//Import components
import App from './App'
import store from './store'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import Loading from './components/Loading'

//Import translations
import './localization'

//Import styles
import './assets/styles/reset.scss'

//Initialization root HTML component
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </Provider>
)
