//Import components
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/team.scss'

//Import images
import one from '../assets/images/team/h2Zme-pUqgc.jpg'
import two from '../assets/images/team/nPjxmMjK-Gk.jpg'
import three from '../assets/images/team/9AK_msTwtBg.jpg'
import four from '../assets/images/team/REB9Fx29KIY.jpg'
import five from '../assets/images/team/ysgtYHcNF8k.jpg'
import six from '../assets/images/team/Xo3WFBwmfTo.jpg'

export default function Team() {
  //Initialization variables
  const { t } = useTranslation()

  return (
    <section title='team' className='team'>
      <div className='team-container'>
        <div className='section-title'>
          <h2>{t('team')}</h2>
          <p>{t('team-description')}</p>
        </div>
        <div className='section-main'>
          <div className='team-item' onClick={() => window.open('https://www.linkedin.com/in/nicolae-budulescu-765325258/?originalSubdomain=md', '_blank')}>
            <img src={one} alt='user' />
            <h3>{t('team-nicolai')}</h3>
            <p>{t('nicolai-description')}</p>
          </div>
          <div className='team-item' onClick={() => window.open('https://www.linkedin.com/in/irina-cojocari-071494258/', '_blank')}>
            <img src={two} alt='user' />
            <h3>{t('team-irina')}</h3>
            <p>{t('irina-description')}</p>
          </div>
          <div className='team-item' onClick={() => window.open('https://www.linkedin.com/in/m34r7/', '_blank')}>
            <img src={three} alt='user' />
            <h3>{t('team-mihail')}</h3>
            <p>{t('mihail-description')}</p>
          </div>
          <div className='team-item' onClick={() => window.open('https://www.linkedin.com/in/vladislav-cusnir-139822258/', '_blank')}>
            <img src={four} alt='user' />
            <h3>{t('team-vladislav')}</h3>
            <p>{t('vladislav-description')}</p>
          </div>
          <div className='team-item' onClick={() => window.open('https://www.instagram.com/apparatovv/', '_blank')}>
            <img src={five} alt='user' />
            <h3>{t('team-artem')}</h3>
            <p>{t('artem-description')}</p>
          </div>
          <div className='team-item' onClick={() => window.open('https://www.instagram.com/alo.da.mititey/', '_blank')}>
            <img src={six} alt='user' />
            <h3>{t('team-dmitrii')}</h3>
            <p>{t('dmitrii-description')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
