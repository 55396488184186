//Import components
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/career.scss'

//Import images
import nodejs from '../assets/images/nodejs.webp'
import designer from '../assets/images/react.jpg'

export default function Career() {
  //Initialization variables
  const { t } = useTranslation()

  return (
    <section className='career' title='career'>
      <div className='career-container'>
        <div className='section-title'>
          <h2>{t('career')}</h2>
          <p>{t('career-description')}</p>
        </div>
        <div className='section-main'>
          <div className='section-item'>
            <img src={nodejs} alt='nodejs' />
            <span>15.12.2022</span>
            <h3>Senior Node.js Developer</h3>
            <p>
              - Experience with Node.js (minimum 1 year); <br />
              - Experience with MongoDB or PostgreSQL <br />
              - Experience with RESTful API
              <br />
            </p>
            <a href='https://www.linkedin.com/company/irnic-solutions-s-r-l/' rel='noreferrer' target='_blank'>
              Read more ➜
            </a>
          </div>
          <div className='section-item'>
            <img src={designer} alt='designer' />
            <span>15.12.2022</span>
            <h3>Senior React Native Developer</h3>
            <p>
              - Experience with React Native (minimum 1 year); <br />
              - Experience working in Agile development environment <br />
              - Git experience required ( GitLab a plus ) <br />
            </p>
            <a href='https://www.linkedin.com/company/irnic-solutions-s-r-l/' rel='noreferrer' target='_blank'>
              Read more ➜
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
