//Import components
import Slider from 'react-slick'
import { useSelector } from 'react-redux/es/exports'

//Import styles
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function MySlider({ sliderContent }) {
  //Initialization state manager
  const page = useSelector(state => state.mainReducer.page)

  //Settings for slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: page ? (page > 720 ? 5 : 1) : 1,
    slidesToScroll: 1,
  }

  return <Slider {...settings}>{sliderContent}</Slider>
}
