//Import components
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/footer.scss'

//Import images
import logo from '../assets/images/logo.webp'
import twitter from '../assets/images/icons/twitter.svg'
import linkedIn from '../assets/images/icons/linkedIn.svg'
import instagram from '../assets/images/icons/instagram.svg'
import email from '../assets/images/icons/email.svg'
import phone from '../assets/images/icons/phone.svg'
import location from '../assets/images/icons/location.svg'

export default function Footer() {
  //Initialization variables
  const { t } = useTranslation()

  return (
    <footer>
      <section>
        <div className='footer-block'>
          <Link to='about' activeClass='active' spy={true} smooth={true} offset={-90} duration={500} className='logo-block'>
            <img src={logo} alt='wonderpals logo' className='logo' />
          </Link>
          <h1>{t('about-title')}</h1>
          <div className='socials'>
            <a href='#twitter'>
              <img src={twitter} alt='twitter' />
            </a>
            <a href='https://www.linkedin.com/company/irnic-solutions-s-r-l/' target='_blank' rel='noreferrer'>
              <img src={linkedIn} alt='linkedIn' />
            </a>
            <a href='#instagram'>
              <img src={instagram} alt='instagram' />
            </a>
          </div>
        </div>
        <div className='contacts'>
          <b>Contacts</b>
          <a href='https://goo.gl/maps/vJ9K2XMSBRRzdYJj8' target='_blank' rel='noreferrer'>
            <img src={location} alt='location' /> Strada Columna 86, Chișinău
          </a>
          <a href='tel:+37362129924'>
            <img src={phone} alt='phone' /> +373 (62) 12 - 99 - 24
          </a>
          <a href='tel:+37369271154'>
            <img src={phone} alt='phone' /> +373 (69) 27 - 11 - 54
          </a>
          <a href='mailto: info@wonderpals.md'>
            <img src={email} alt='email' /> info@wonderpals.md
          </a>
        </div>
        <div className='navigation'>
          <b>Navigation</b>

          <Link to='about' activeClass='active' spy={true} smooth={true} offset={-90} duration={500}>
            {t('about')}
          </Link>
          <Link to='services' activeClass='active' spy={true} smooth={true} offset={-90} duration={500}>
            {t('services')}
          </Link>
          <Link to='team' activeClass='active' spy={true} smooth={true} offset={-65} duration={500}>
            {t('team')}
          </Link>
          <Link to='cases' activeClass='active' spy={true} smooth={true} offset={-60} duration={500}>
            {t('cases')}
          </Link>
          <Link to='clients' activeClass='active' spy={true} smooth={true} offset={-45} duration={500}>
            {t('clients')}
          </Link>
          <Link to='career' activeClass='active' spy={true} smooth={true} offset={-30} duration={500}>
            {t('career')}
          </Link>
        </div>
      </section>
      <section>
        <p>© {new Date().getFullYear()}</p> Wonderpals. All rights reserved.
      </section>
    </footer>
  )
}
