//Import components
import About from './About'
import Services from './Services'
import Team from './Team'
import Cases from './Cases'
import Clients from './Clients'
import Career from './Career'

export default function Home() {
  return (
    <>
      <About />
      <Services />
      <Team />
      <Cases />
      <Clients />
      <Career />
    </>
  )
}
