//Import components
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/about.scss'

//Import images
import background from '../assets/images/background.webp'

export default function About() {
  //Initialization variables
  const { t } = useTranslation()

  return (
    <section className='about' title='about'>
      <div className='about-container'>
        <div className='header-block'>
          <h1>{t('about-title')}</h1>
          <p>{t('about-description')}</p>
          <a href='https://www.linkedin.com/company/irnic-solutions-s-r-l/' rel='noreferrer' target='_blank'>
            {t('learn more')}
          </a>
        </div>
        <img src={background} alt='background' />
      </div>
    </section>
  )
}
