//Import components
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/cases.scss'

//Import images
import duck from '../assets/images/cases/2.png'
import labworq from '../assets/images/cases/4.png'
import udazzy from '../assets/images/cases/6.png'

export default function Cases() {
  //Initialization variables
  const { t } = useTranslation()

  return (
    <section className='cases' title='cases'>
      <div className='cases-container'>
        <div className='section-title'>
          <h2>{t('cases')}</h2>
          <p>{t('cases-description')}</p>
        </div>
        <div className='section-main'>
          <div className='section-item'>
            <img src={duck} alt='nodejs' />
            <span>05.11.2022</span>
            <h3>DUCK</h3>
            <p>{t('Duck-descriptions')}</p>
            <a href='https://duckscooters.com/' target='_blank' rel='noreferrer'>
              Read more ➜
            </a>
          </div>
          <div className='section-item'>
            <img src={labworq} alt='nodejs' />
            <span>05.11.2022</span>
            <h3>Labworq</h3>
            <p>{t('Labworq-description')}</p>
            <a href='https://labworq.com/' target='_blank' rel='noreferrer'>
              Read more ➜
            </a>
          </div>
          <div className='section-item'>
            <img src={udazzy} alt='manager' />
            <span>22.10.2022</span>
            <h3>UDAZZY - Find your party</h3>
            <p>{t('Udazzy-descriptions')}</p>
            <a href='https://udazzy.com/' target='_blank' rel='noreferrer'>
              Read more ➜
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
