//Import styles
import '../assets//styles/loading.scss'

export default function Loading() {
  return (
    <div className='loading-container'>
      <div className='lds-ellipsis'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
