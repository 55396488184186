//Import components
import MySlider from '../components/Slider'
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/clients.scss'

//Import images
import labworq from '../assets/images/clients/labworq.webp'
import healeze from '../assets/images/clients/healeze.webp'
import udazzy from '../assets/images/clients/udazzy.webp'
import computeranswers from '../assets/images/clients/computer_answers.webp'
import duckscooters from '../assets/images/clients/duck.webp'
import nanoliferobotics from '../assets/images/clients/nano_life.webp'
import softncushyauto from '../assets/images/clients/snc_auto.webp'
import stabilitas from '../assets/images/clients/stabilitas.webp'

export default function Clients() {
  //Initialization variables
  const { t } = useTranslation()

  //Images for slider
  const images = [
    { image: labworq, link: 'https://labworq.com/' },
    { image: healeze, link: 'https://healeze.com/' },
    { image: udazzy, link: 'https://udazzy.com/' },
    { image: computeranswers, link: 'https://computeranswers.com/' },
    { image: duckscooters, link: 'https://duckscooters.com/' },
    { image: nanoliferobotics, link: 'https://nanoliferobotics.com/' },
    { image: softncushyauto, link: 'https://softncushyauto.com/' },
    { image: stabilitas, link: 'https://stabilitas.md/' },
  ]

  const sliderContent = images.map(child => {
    return (
      <div key={child} className='slider-item' onClick={() => window.open(child.link, '_blank')}>
        <img src={child.image} alt={child.link} />
      </div>
    )
  })

  return (
    <section className='clients' title='clients'>
      <div className='clients-container'>
        <div className='section-title'>
          <h2>{t('clients')}</h2>
          <p>{t('clients-description')}</p>
        </div>
        <div className='section-main'>
          <MySlider sliderContent={sliderContent} />
        </div>
      </div>
    </section>
  )
}
