//Import components
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Router from './Router'
import Home from './pages/Home'
import Error from './pages/Error'
import Terms from './pages/Terms'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Router />}>
          <Route index element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
