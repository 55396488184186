//Import components
import { Outlet } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'

export default function Router() {
  return (
    <>
      <Header />
      <main className='main-home'>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
