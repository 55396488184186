//Import components
import { useTranslation } from 'react-i18next'

//Import styles
import '../assets/styles/services.scss'

//Import images
import supporting from '../assets/images/icons/supporting.svg'
import marketing from '../assets/images/icons/marketing.svg'
import development from '../assets/images/icons/development.svg'
import management from '../assets/images/icons/management.svg'

export default function Services() {
  //Initialization variables
  const { t } = useTranslation()

  return (
    <section className='services' title='services'>
      <div className='services-container'>
        <div className='section-title'>
          <h2>{t('services')}</h2>
     
        </div>
        <div className='section-main'>
          <div className='section-item'>
            <img src={supporting} alt='supporting' />
            <h3>Supporting</h3>
            <p>{t('supporting-description')}</p>
          </div>
          <div className='section-item'>
            <img src={marketing} alt='marketing' />
            <h3>Marketing</h3>
            <p>{t('marketing-description')}</p>
          </div>
          <div className='section-item'>
            <img src={development} alt='development' />
            <h3>Development</h3>
            <p>{t('development-description')}</p>
          </div>
          <div className='section-item'>
            <img src={management} alt='management' />
            <h3>Management</h3>
            <p>{t('management-description')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
