//Import components
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { pageEdit } from '../store/slices/main'

//Import styles
import '../assets/styles/header.scss'

//Import images
import logo from '../assets/images/logo.webp'
import ro from '../assets/images/icons/ro.svg'
import ru from '../assets/images/icons/ru.svg'
import en from '../assets/images/icons/en.svg'

export default function Header() {
  //Initialization variables
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }

  //Initialization state manager
  const header = useRef(null)
  const [languageModal, setLanguageModal] = useState(false)
  const [burgerModal, setBurgerModal] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.screen.width)

  //Function for listen page weight
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    dispatch(pageEdit(window.innerWidth))
    window.onresize = () => {
      setWindowWidth(window.innerWidth)
      dispatch(pageEdit(window.innerWidth))
    }
    return () => {
      window.onresize = false
    }
  }, [dispatch])

  //Function for close language modal
  useEffect(() => {
    const mouseUp = e => {
      if (e.target.closest('.language-modal') === null) {
        setLanguageModal(false)
      }
      if (e.target.closest('.sub-menu') === null) {
        setBurgerModal(false)
      }
    }
    document.addEventListener('mouseup', mouseUp)
    return function () {
      document.removeEventListener('mouseup', mouseUp)
    }
  }, [])

  //Function for edit header on scroll
  useEffect(() => {
    const scrollPage = e => {
      if (window.pageYOffset >= 70) {
        header.current.classList.add('scrolled')
      } else {
        header.current.classList.remove('scrolled')
      }
    }
    document.addEventListener('scroll', scrollPage)
    return function () {
      document.removeEventListener('scroll', scrollPage)
    }
  }, [])

  //Detection current language end render in header
  useEffect(() => {
    if (i18n.language === 'ro') {
      setCurrentLanguage(
        <button onClick={() => setLanguageModal(!languageModal)} className='language'>
          <img src={ro} alt='romanian' />
          Română
        </button>
      )
    } else if (i18n.language === 'ru') {
      setCurrentLanguage(
        <button onClick={() => setLanguageModal(!languageModal)} className='language'>
          <img src={ru} alt='russian' />
          Русский
        </button>
      )
    } else {
      setCurrentLanguage(
        <button onClick={() => setLanguageModal(!languageModal)} className='language'>
          <img src={en} alt='english' />
          English
        </button>
      )
    }
  }, [i18n.language, languageModal])

  return windowWidth >= 720 ? (
    <header ref={header}>
      <nav>
        <Link to='about' activeClass='active' className='logo' spy={true} smooth={true} offset={-90} duration={500}>
          <img src={logo} alt='wonderpals logo' />
        </Link>
        <Link to='about' activeClass='active' spy={true} smooth={true} offset={-90} duration={500}>
          {t('about')}
        </Link>
        <Link to='services' activeClass='active' spy={true} smooth={true} offset={-90} duration={500}>
          {t('services')}
        </Link>
        <Link to='team' activeClass='active' spy={true} smooth={true} offset={-65} duration={500}>
          {t('team')}
        </Link>
        <Link to='cases' activeClass='active' spy={true} smooth={true} offset={-60} duration={500}>
          {t('cases')}
        </Link>
        <Link to='clients' activeClass='active' spy={true} smooth={true} offset={-45} duration={500}>
          {t('clients')}
        </Link>
        <Link to='career' activeClass='active' spy={true} smooth={true} offset={-30} duration={500}>
          {t('career')}
        </Link>
        {currentLanguage}
        <div className={languageModal ? 'language-modal active' : 'language-modal'}>
          <button
            onClick={() => {
              changeLanguage('en')
              setLanguageModal(false)
            }}>
            <img src={en} alt='english' />
            English
          </button>
          <button
            onClick={() => {
              changeLanguage('ru')
              setLanguageModal(false)
            }}
            className='test'>
            <img src={ru} alt='russian' />
            Русский
          </button>
          <button
            onClick={() => {
              changeLanguage('ro')
              setLanguageModal(false)
            }}>
            <img src={ro} alt='romanian' />
            Română
          </button>
        </div>
      </nav>
    </header>
  ) : (
    <header ref={header}>
      <nav>
        <Link to='about' activeClass='active' className='logo' spy={true} smooth={true} offset={-90} duration={500}>
          <img src={logo} alt='wonderpals logo' />
        </Link>
        {currentLanguage}
        <div className={languageModal ? 'language-modal active' : 'language-modal'}>
          <button
            onClick={() => {
              changeLanguage('en')
              setLanguageModal(false)
            }}>
            <img src={en} alt='english' />
            English
          </button>
          <button
            onClick={() => {
              changeLanguage('ru')
              setLanguageModal(false)
            }}
            className='test'>
            <img src={ru} alt='russian' />
            Русский
          </button>
          <button
            onClick={() => {
              changeLanguage('ro')
              setLanguageModal(false)
            }}>
            <img src={ro} alt='romanian' />
            Română
          </button>
        </div>
        <div className={burgerModal ? 'burger active' : 'burger'} onClick={() => setBurgerModal(!burgerModal)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={burgerModal ? 'sub-menu active' : 'sub-menu'}>
          <Link to='about' activeClass='active' spy={true} smooth={true} offset={-90} duration={500}>
            {t('about')}
          </Link>
          <Link to='services' activeClass='active' spy={true} smooth={true} offset={-90} duration={500}>
            {t('services')}
          </Link>
          <Link to='team' activeClass='active' spy={true} smooth={true} offset={-65} duration={500}>
            {t('team')}
          </Link>
          <Link to='cases' activeClass='active' spy={true} smooth={true} offset={-60} duration={500}>
            {t('cases')}
          </Link>
          <Link to='clients' activeClass='active' spy={true} smooth={true} offset={-45} duration={500}>
            {t('clients')}
          </Link>
          <Link to='career' activeClass='active' spy={true} smooth={true} offset={-30} duration={500}>
            {t('career')}
          </Link>
        </div>
      </nav>
    </header>
  )
}
